/** @jsx jsx */

import React from 'react';
import List from './components/List'
import { jsx } from '@emotion/core'
import { styles } from './components/App.styles'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { generateCombination } from 'gfycat-style-urls'
import { MdStarBorder } from 'react-icons/md'




class App extends React.Component {


  constructor(props) {
    super(props);
    const newListId = this.getRandomUrl()
    this.state = { newListId, notes: [], filter: 'none' };
  }

  async componentDidMount() {
    console.log(this.getRandomInt(100) + '-' + generateCombination(2, '-').toLowerCase() + 's')
  }

  createNewList() {
    this.setState({
      newListId: this.getRandomUrl()
    })
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  getRandomUrl() {
    return this.getRandomInt(100) + '-' + generateCombination(2, '-').toLowerCase() + 's'
  }


  ComponentWithRegex({ match }) {
    return (
      <div>
        <h3>Only asc/desc are allowed: {match.params.direction}</h3>
      </div>
    );
  }





  render() {
    let { notes, filter } = this.state
    if (filter === 'checked') {
      notes = notes.filter(n => n.checked === true)
    }
    if (filter === 'unchecked') {
      notes = notes.filter(n => n.checked === false)
    }
    return (
      <div >
        <Router>
          <header style={styles.container}>
            <Link style={{ textShadow: `none`, backgroundImage: `none` }} to={"/" + this.state.newListId}>

              <h2 style={{ display: `inline` }}>GitLists</h2>
            </Link>


            <ul style={{ listStyle: `none`, float: `right` }}>
              <li style={{ display: 'inline-block', marginRight: '1rem', }}>
                <Link style={{ backgroundImage: `none` }} to={"/" + this.state.newListId}>My Lists</Link>
              </li>
              <li style={{ display: 'inline-block', marginRight: '1rem' }}>
                <Link style={{ backgroundImage: `none` }} to={"/" + this.state.newListId}>New List</Link>
              </li>
              <li style={{ display: 'inline-block' }}>
                <Link style={{ backgroundImage: `none` }} to={"/" + this.state.newListId}>Clone List</Link>
              </li>

            </ul>
          </header>

          <Route path="/:listId" render={(props) => (
            <List key={props.match.params.listId} {...props} />)
          } />

        </Router>
      </div >
    );


  }
}



export default App;