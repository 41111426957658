/** @jsx jsx */

import React from 'react'
import { jsx } from '@emotion/core'
import { MdAdd } from 'react-icons/md'
import { styles } from './Form.styles'


class Form extends React.Component {
  state = { name: '' }
  onChange = e => {
    this.setState({ name: e.target.value })
  }
  handleKeyPress = (e) => {
    if (e.key === 'Enter' && this.state.name !== '') {
      const note = {
        ...this.state, checked: false
      }
      this.props.createNote(note)
      this.setState({ name: '' })
    }
  }
  render() {
    return (
      <div css={styles.container}>
        <div css={styles.form}>
          <MdAdd size={28} />
          <input
            css={styles.input}
            placeholder='new item...'
            onKeyPress={this.handleKeyPress}
            onChange={e => this.onChange(e)}
            value={this.state.name}
          />
        </div>
      </div>
    )
  }
}



export default Form
