export const styles = {
  container: {
    color: '#464646',

    margin: `1rem auto`,
    maxWidth: 650,
    padding: `0 1rem`,
    margin: 0,
    // backgroundColor: 'red',
    padding: 0,
  },

  checkboxIconContainer: {
    marginRight: 10,
  },
  iconContainer: {
    marginLeft: 20,
  },

  times: {
    cursor: 'pointer',
    opacity: 0.7
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    // borderBottom: '1px solid #ededed',
    marginTop: 15,
    marginBottom: 15

  },
  itemInnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  itemTextContainer: {
    marginLeft: 10,
  },
  itemTextChecked: {
    // color: '#464646'
    color: '#999999'
    // color: '#1ca086'
  }
}