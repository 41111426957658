import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import Typography from 'typography'
// import lawtonTheme from 'typography-theme-lawton'
// import irvingTheme from 'typography-theme-irving'
import fairyGateTheme from 'typography-theme-fairy-gates'

const typography = new Typography(fairyGateTheme)
typography.injectStyles({ bodyGray: 90, headerGray: 90 })

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
