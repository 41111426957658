export const styles = {
  container: {
    // borderBottom: '1px solid #ededed',
    margin: `2rem auto`,
    maxWidth: 650,
    padding: `0 1.5rem`,
    // backgroundColor: 'red'
  },
  menuContainer: {
    display: 'flex',
    flexDirection: "row",
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: `0.3rem`
  },
  menuTitle: {
    // marginTop: 10,
    // marginBottom: 10,
    // marginRight: 100
    marginTop: 10,
    marginBotton: 10
  },
  menuItemsContainer: {
    display: 'flex',
    flexDirection: "row",
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  menuItem: {
    display: 'flex',
    cursor: 'pointer',
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 5,
    marginRight: 0
  },

}