/** @jsx jsx */

import React from 'react'
import { FaTimes } from 'react-icons/fa'
import { jsx } from '@emotion/core'
import { styles } from './ListItem.styles'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'



class ListItem extends React.Component {
  render() {
    const { name, checked, id } = this.props.note
    return (
      <div css={styles.container} >
        <div css={styles.itemContainer}>
          <div css={styles.itemInnerContainer}>
            {checked ? <MdCheckBox size={24} onClick={() => this.props.updateNote(this.props.note)}
            /> : <MdCheckBoxOutlineBlank size={24} onClick={() => this.props.updateNote(this.props.note)}
              />}
            <div css={[styles.itemTextContainer, checked ? styles.itemTextChecked : null]}>
              {name}
            </div>
          </div>

          <div css={styles.iconContainer}>
            <FaTimes
              onClick={() => this.props.deleteNote(this.props.note)}
              color='red'
              size={14}
              css={styles.times}
            />
          </div>
        </div>
      </div >
    )
  }
}


export default ListItem


{/* <div css={styles.itemContainer}>
<div css={styles.checkboxIconContainer}>
  <MdAdd size={28} onClick={() => this.props.updateNote(this.props.note)}
  />
</div>
<div css={styles.itemTextContainer}>
  <p css={styles.itemText}>{name}</p>
</div>
<div css={styles.iconContainer}>
  <FaTimes
    onClick={() => this.props.deleteNote(this.props.note)}
    color='red'
    size={22}
    css={styles.times}
  />
</div>
</div > */}
