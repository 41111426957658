export const styles = {
  container: {
    // margin: `1rem auto`,
    // maxWidth: 650,
    // padding: `0 1rem`,
    // display: 'flex',
    // justifyContent: 'flex-start',
    // backgroundColor: 'blue'
    // padding: `0 0.5rem`,

  },
  form: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ededed',
    padding: 6
  },
  input: {
    height: 35,
    // width: '360px',
    border: 'none',
    outline: 'none',
    marginLeft: 10,
    fontSize: 20,
    padding: 8,
  }
}