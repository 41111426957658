import firebase from 'firebase'

var firebaseConfig = {
  apiKey: "AIzaSyAJ0pRWLqxzJmHbgE3MaDMUaQ8tjUu52Uw",
  authDomain: "gitlists-1558204877753.firebaseapp.com",
  databaseURL: "https://gitlists-1558204877753.firebaseio.com",
  projectId: "gitlists-1558204877753",
  storageBucket: "gitlists-1558204877753.appspot.com",
  messagingSenderId: "509744481474",
  appId: "1:509744481474:web:95faec2f634876b7"
};

var fire = firebase.initializeApp(firebaseConfig);
export default fire;
