/** @jsx jsx */

import React from 'react'
import { jsx } from '@emotion/core'
import ListItem from './ListItem'
import { styles } from './NotesList.styles'


class Notes extends React.Component {
  render() {
    return (
      <div css={styles.container} >
        {
          this.props.notes.map((t, i) => (
            <ListItem
              key={i}
              note={t}
              deleteNote={this.props.deleteNote}
              updateNote={this.props.updateNote}
            />
          ))
        }
      </div>
    )
  }
}



export default Notes
