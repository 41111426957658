/** @jsx jsx */

import React from 'react';
import Form from './Form'
import NotesList from './NotesList'
import { jsx } from '@emotion/core'
import { styles } from './List.styles'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'


import fire from '../firebase';

class App extends React.Component {


  constructor(props) {
    super(props);
    let { listId } = this.props.match.params
    const listRef = fire.database().ref(`lists/${listId}/`)
    const baseRef = fire.database().ref('lists')

    this.state = { listId, listRef, baseRef, notes: [], metadata: { title: "..." }, editTitle: false, filter: 'none' };
  }

  async componentDidMount() {
    this.refreshList()
    this.getMetadata()
    alert(this.getClientIp())
  }

  refreshList() {
    const { listRef } = this.state

    let notesRef = listRef.orderByKey().limitToLast(100);
    notesRef.on('child_added', snapshot => {
      const note = snapshot.val()
      let notes = [note, ...this.state.notes]
      notes = notes.filter(n => n.id != null)
      this.setState({ notes })
    })
  }

  getMetadata = () => {
    const { listId, metadata } = this.state
    var metadataRef = fire.database().ref(`lists/${listId}/metadata`)
    metadataRef.on('value', (snapshot) => {
      this.setState({ metadata: snapshot.val() })
    });

  }

  getClientIp = (req) => {
    // const os = require('os');
    const ip = require('ip');
    // console.log(os.networkInterfaces());
    console.log(ip.address())

    return ip.address()
  }


  updateTitle = (title) => {
    const { listId } = this.state
    const metaData = { title }

    this.updateFbObject(`lists/${listId}/metadata`, metaData)

  }


  createNote = async note => {
    const { listId, baseRef } = this.state
    try {
      let id = baseRef.child(listId).push().key
      note.id = id
      this.updateFbObject(`/lists/${listId}/${note.id}`, note)
    } catch (err) {
      console.log('error creating note..', err)
    }
  }

  updateNote = async note => {
    const { listId } = this.state

    const updatedNote = {
      ...note,
      checked: note.checked ? false : true
    }
    const index = this.state.notes.findIndex(i => i.id === note.id)
    const notes = [...this.state.notes]
    notes[index] = updatedNote
    this.setState({ notes })

    this.updateFbObject(`/lists/${listId}/${note.id}`, updatedNote)
  }

  updateFbObject = async (path, object) => {
    try {
      let updates = {};
      updates[path] = object;
      return fire.database().ref().update(updates);
    } catch (err) {
      console.log('error updating...', err)
    }
  }


  deleteNote = async note => {
    const { listId } = this.state

    const notes = this.state.notes.filter(n => n.id !== note.id)
    this.setState({ notes })
    try {
      let noteRef = fire.database().ref(`/lists/${listId}/${note.id}`)
      return noteRef.remove()
    } catch (err) {
      console.log('error deleting note...', err)
    }
  }

  updateFilter = filter => this.setState({ filter })

  onChange = e => {
    this.setState({ metadata: { title: e.target.value } })
  }
  handleKeyPress = (e) => {
    if (e.key === 'Enter' && this.state.metadata.title !== '') {
      this.updateTitle(this.state.metadata.title)
      this.setState({ editTitle: false })
    }
  }


  render() {
    let { notes, filter, metadata, editTitle } = this.state
    if (filter === 'checked') {
      notes = notes.filter(n => n.checked === true)
    }
    if (filter === 'unchecked') {
      notes = notes.filter(n => n.checked === false)
    }
    return (
      <div css={styles.container}>

        <div css={styles.menuContainer} >
          <div>
            {editTitle ? <input
              css={styles.input}
              placeholder='add new item...'
              onKeyPress={this.handleKeyPress}
              onChange={e => this.onChange(e)}
              value={metadata.title}
            /> : <h4 onClick={() => this.setState({ editTitle: true })} css={styles.menuTitle}>{metadata && metadata.title} </h4>
            }

          </div>
          <div css={styles.menuItemsContainer}>
            <div
              css={styles.menuItem}
              onClick={() => this.updateFilter('none')}

            >All</div>

            <div
              css={styles.menuItem}

              onClick={() => this.updateFilter('unchecked')}
            >
              <MdCheckBoxOutlineBlank size={24} onClick={() => this.updateFilter('unchecked')}
              />
            </div>
            <div
              css={styles.menuItem}

              onClick={() => this.updateFilter('checked')}
            >
              <MdCheckBox size={24} onClick={() => this.updateFilter('checked')}
              />
            </div>
          </div>
        </div>

        <Form
          createNote={this.createNote}
        />
        <NotesList
          notes={notes}
          deleteNote={this.deleteNote}
          updateNote={this.updateNote}
        />

      </div >
    );


  }
}



export default App;