export const styles = {
  container: {
    margin: `1rem auto`,
    maxWidth: 650,
    padding: `0 1rem`,
  },
  // form: {
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'center'
  // },
  // input: {
  //   height: 35,
  //   width: '360px',
  //   border: 'none',
  //   outline: 'none',
  //   marginLeft: 10,
  //   fontSize: 20,
  //   padding: 8,
  // },
  // menuItem: {
  //   cursor: 'pointer',
  //   margin: 10
  // },

}